.calendar-content,
.calendar-controls {
   display: flex;
   justify-content: center;
   align-items: center;
   line-height: 2em;
   margin: 1em 0;
}

.current-year {
   font-size: 2em;
   margin: 0 2em;
   font-weight: bold;
}

.control {
   font-size: 1.5em;
   font-weight: bold;
}

table.calendar {
   border-collapse: collapse;
}

table.calendar thead {
   background-color: #282c34;
   border-bottom: 2px solid #fff;
   color: #fff;
   margin-bottom: 3px;
}

table.calendar thead th {
   font-weight: 400;
   padding: 10px 3px;
   font-weight: bold;
}

table.calendar tbody td {
   border: 1px solid hsla(0, 0%, 73%, 0.13);
   min-width: 15px;
   padding: 8px;
   text-align: center;
}

table.calendar tbody td.events {
   background-color: #36c3f9;
   cursor: pointer;
}

table.calendar td.month-name {
   font-weight: bold;
}

table.calendar td.next-month,
table.calendar td.prev-month {
   background-image: url(/public/cellule.png);
   color: transparent;
   cursor: default;
   pointer-events: none;
}

table.calendar td.week-separator {
   background-image: url(/public/cellule.png);
   min-width: 0;
   padding: 0;
   pointer-events: none;
   width: 8px;
}

.popover-content {
   margin: 15px;
   text-align: center;
}

.popover-title {
   font-weight: bold;
}
