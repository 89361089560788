.App {
   text-align: center;
}

.App .home {
   margin-top: 1em;
   font-size: 2em;
   font-weight: bold;
}

.App-section {
   text-align: center;
}

.App-link {
   color: #61dafb;
}
