body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

.App-header {
   background-color: #282c34;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
   height: 128px;
}

.App-header a {
   color: white;
   text-decoration: none;
}

.App-header a:hover {
   text-shadow: 0 0 10px #36c3f9;
}

.App-header img:first-of-type {
   position: absolute;
   left: 4px;
   top: 44px;
   height: 40px;
}


.App-header img:last-of-type {
   position: absolute;
   right: 4px;
   top: 4px;
   height: 120px;
}
